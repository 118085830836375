/// <reference path="../../../build/slices/helpers/types.ts" />

export const getFFTE = (property: null | string = null): unknown => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (window.ffte) {
    if (!property) {
      return window.ffte;
    }

    return window.ffte[property];
  }

  return null;
};

export const getMissingImageURL = () => {
  return 'https://vanilla.futurecdn.net/cyclingnews/media/img/missing-image.svg';
};
