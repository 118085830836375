import { getSizedImage } from './imageSize';

const getSrcSet = (imgURL: string, sizes: number[], isWebp: boolean): string => {
  let srcset = '';

  sizes.forEach((size, index) => {
    const separator = sizes.length !== index + 1 ? ', ' : '';
    srcset = `${srcset}${getSizedImage(imgURL, size, isWebp)} ${size}w${separator}`;
  });

  return srcset;
};

export default getSrcSet;
