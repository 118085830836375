export const encodeSpaces = (url: string): string => {
  return url.replace(' ', '%20');
};

export const getURLObject = (imgURL: string | URL): URL => {
  if (typeof imgURL === 'string') {
    return new URL(encodeSpaces(imgURL));
  }

  return imgURL;
};
