import { MOS_REGEX } from './constants';
import isValidURL from './isValidURL';
import { getURLObject } from './normaliseURL';

const getOriginalURL = (imgURL: string): string => {
  if (!isValidURL(imgURL)) {
    return imgURL;
  }
  const url = getURLObject(imgURL);
  const mosRegex = new RegExp(MOS_REGEX, 'i');
  const found = url.pathname.match(mosRegex);

  let path = '';
  if (url.pathname && found?.groups) {
    path = `${found.groups.id}.${found.groups.ext}`;
  }

  let protocol = '';
  if (url.protocol && url.host) {
    protocol = `${url.protocol}//${url.host}`;
  }

  return protocol + path;
};

export default getOriginalURL;
