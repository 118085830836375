const isMOSImage = (imgURL: string | URL): boolean => {
  if (typeof imgURL === 'object' && imgURL.origin) {
    return isMOSImage(imgURL.origin);
  }

  if (typeof imgURL === 'string') {
    return imgURL.includes('.mos.cms.futurecdn.net');
  }

  return false;
};

export default isMOSImage;
