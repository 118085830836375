const isValidURL = (value: string) => {
  try {
    new URL(value); // eslint-disable-line no-new
    return true;
  } catch (err) {
    return false;
  }
};

export default isValidURL;
