import { MOS_REGEX } from './constants';
import isValidURL from './isValidURL';
import { getURLObject } from './normaliseURL';

export const getSizedImage = (
  imgURL: string,
  size: number,
  isWebp = false,
  imageQuality = 80,
): string => {
  const webp = isWebp ? '.webp' : '';

  if (!isValidURL(imgURL)) {
    return imgURL;
  }
  const url = getURLObject(imgURL);
  const mosRegex = new RegExp(MOS_REGEX, 'i');
  const found = url.pathname.match(mosRegex);

  let path = '';
  if (url.pathname && found) {
    path = `${found?.groups?.id}-${size}-${imageQuality}.${found?.groups?.ext}${webp}`;
  }

  let protocol = '';
  if (url.protocol && url.host) {
    protocol = `${url.protocol}//${url.host}`;
  }

  return protocol + path;
};

interface IBreakpoint {
  [key: string]: string;
}

export const getImageSizes = (breakpoints: IBreakpoint): string => {
  let breakpointsAttr = '';
  let defaultValue = '';
  Object.entries(breakpoints).forEach((entry) => {
    const [breakpoint, size] = entry;

    if (breakpoint === 'default') {
      defaultValue = size;
    } else {
      breakpointsAttr = `${breakpointsAttr}(min-width: ${breakpoint}) ${size}, `;
    }
  });
  breakpointsAttr = `${breakpointsAttr}${defaultValue}`;

  return breakpointsAttr;
};

const getParent = (element: HTMLElement): HTMLElement => {
  const parent = element.parentElement;
  if (!parent) {
    return element;
  }
  if (parent.tagName.toLowerCase() === 'picture') {
    return parent.parentElement || parent;
  }
  return parent;
};

const setSizesAttr = (image: HTMLElement, width: number): void => {
  image.setAttribute('sizes', `${width}px`);
  // Set class so we can grab on resize
  image.classList.add('manual-image-sizes');
};

// Keep getting parent of element until one gives an offsetWidth
const grabParentOffsetWidth = (parent: HTMLElement): number => {
  const width = parent.offsetWidth;
  return width < 1 ? grabParentOffsetWidth(getParent(parent)) : width;
};

const setSizesOnImages = (images: NodeListOf<HTMLElement>): void => {
  images.forEach((image) => {
    const parent = getParent(image);
    try {
      setSizesAttr(image, grabParentOffsetWidth(parent));
    } catch (err) {
      console.log(err);
    }
  });
};

export const setImagesSizesAttr = (refElement: HTMLElement | null): void => {
  /**
   * 99vw is the default sizes is set to, in Image.php, meaning there isn't a proper defined sizes,
   * and we want to make sure the right size image is loaded, so we will just set it to exactly
   * what the image size is displaying at now.
   *
   * Images that have a defined sizes attribute are all good, leave them alone,
   * this is a hack for less predictable images,e.g. search (can be 250px in one place,
   * and 100vw in another)
   */
  const querySelectorRedo = '[data-slice-image].manual-image-sizes';
  const querySelectorImages = '[data-slice-image][sizes="99vw"]';

  const element = refElement || document;

  // Images that have already been processed by this file that need re-doing i.e. on resize
  const redoImages = element.querySelectorAll(querySelectorRedo) as NodeListOf<HTMLElement>;
  if (redoImages.length > 0) {
    setSizesOnImages(redoImages);
  }

  // Images that have our default sizes value
  const images = element.querySelectorAll(querySelectorImages) as NodeListOf<HTMLElement>;
  if (images.length > 0) {
    setSizesOnImages(images);
  }
};
