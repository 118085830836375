import { MOS_REGEX } from './constants';
import isValidURL from './isValidURL';
import { getURLObject } from './normaliseURL';

export const getFileExtension = (imgURL: string): string => {
  const mosRegex = new RegExp(MOS_REGEX, 'i');

  if (!isValidURL(imgURL)) {
    return '';
  }
  const url = getURLObject(imgURL);
  const found = url.pathname.match(mosRegex);
  const ext: string = found?.groups?.ext || '';

  return ext;
};

export const getImageType = (imgURL: string): string => {
  let ext = getFileExtension(imgURL);

  if (ext === '') {
    return ext;
  }

  if (ext === 'jpg') {
    ext = 'jpeg';
  }

  return `image/${ext}`;
};
