import React, { useRef, useState } from 'react';

import { SIZES, SRCSETSIZES } from './constants';
import getOriginalURL from './getOriginalURL';
import getSrcSet from './getSrcSet';
import { getImageSizes } from './imageSize';
import { getFileExtension, getImageType } from './imageType';
import { IImageAttrs, IImageProps } from './interfaces/responsifyImage.interfaces';
import isMOSImage from './isMOSImage';
import { getMissingImageURL } from './utils';

const ResponsiveImage: React.FC<IImageProps> = ({
  src: iURL,
  alt,
  className = '',
  attributes = {},
  srcSetSizes = SRCSETSIZES,
  sizes = SIZES,
  missingImageSrc = getMissingImageURL(),
  onLoad = () => {},
  setImageRef = () => {},
  lazyLoading = true,
  useOriginalUrl = false,
  dataHydrate,
  ..._otherAttr
}) => {
  // TODO: Pinterest tweaks.

  const firstSourceRef = useRef<HTMLSourceElement | null>(null);
  const secondSourceRef = useRef<HTMLSourceElement | null>(null);

  const [imageClassName, setImageClassName] = useState<string>(className);
  const [errorState, setErrorState] = useState<boolean>(false);

  const onImageLoad = () => {
    if (!imageClassName.includes('loaded')) {
      setImageClassName((oldClass) => `${oldClass} loaded`);
    }
    onLoad();
  };

  const imgURL = useOriginalUrl ? getOriginalURL(iURL) : iURL;

  const dataHydrateValue = dataHydrate !== false;

  if (!isMOSImage(imgURL)) {
    return (
      <img
        data-hydrate={dataHydrateValue}
        key={`nomosimage ${imgURL} ${alt}`}
        src={imgURL}
        alt={alt}
        onLoad={onImageLoad}
        className={imageClassName}
        loading={lazyLoading === false ? 'eager' : 'lazy'}
        {...attributes}
      />
    );
  }

  const imageAttrs: IImageAttrs = {
    src: missingImageSrc,
    dataSrcSet: getSrcSet(imgURL, srcSetSizes, false),
    dataSrcSetWebp: getSrcSet(imgURL, srcSetSizes, true),
    imageSizes: getImageSizes(sizes),
    onError: (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      [firstSourceRef, secondSourceRef].forEach((ref) => {
        if (ref.current) {
          // eslint-disable-next-line no-param-reassign
          ref.current.srcset = e.currentTarget.src;
        }
      });
      setErrorState(true);
    },
    'data-normal': missingImageSrc,
    'data-original-mos': getOriginalURL(imgURL),
    'data-pin-media': getOriginalURL(imgURL),
    'data-pin-nopin': true,
    'data-slice-image': true,
    ...attributes,
  };

  const { src, dataSrcSet, dataSrcSetWebp, imageSizes, onError, ...rest } = imageAttrs;

  function WebpSourceTag() {
    const isGif = getFileExtension(imgURL) === 'gif';

    // We can't convert animated gifs to .webp so just bail out here.
    if (isGif) {
      return null;
    }

    return (
      <source
        className={className}
        type="image/webp"
        srcSet={dataSrcSetWebp}
        sizes={imageSizes}
        ref={(ref) => {
          firstSourceRef.current = ref;
        }}
        key={`${iURL}-webp`}
        {...rest}
      />
    );
  }

  return (
    <picture data-hydrate={dataHydrateValue}>
      <WebpSourceTag />
      <source
        className={className}
        type={getImageType(imgURL)}
        srcSet={dataSrcSet}
        sizes={imageSizes}
        ref={(ref) => {
          secondSourceRef.current = ref;
        }}
        key={`${iURL}-source`}
        {...rest}
      />
      <img
        key={`${src}-image`}
        src={src}
        alt={alt}
        sizes={imageSizes}
        className={imageClassName}
        ref={(ref) => {
          if (ref) {
            setImageRef(ref);
          }
        }}
        onLoad={onImageLoad}
        loading={lazyLoading === false ? 'eager' : 'lazy'}
        onError={!errorState ? onError : null}
        {...rest}
      />
    </picture>
  );
};

export default React.memo(ResponsiveImage);
