import React, { useState } from 'react';

import { IPopularBox } from '~/interfaces/components/PopularBox';

import ArticlesList from './inner-components/ArticlesList/ArticlesList';
import PopLabel from './inner-components/PopLabel/PopLabel';

const PopularBox: React.FC<IPopularBox> = ({ tabs }) => {
  const [activeLabel, setActiveLabel] = useState(0);

  if (!tabs.length) {
    return null;
  }

  const articlesComponent = tabs.map((tab, index) => (
    <ArticlesList
      activeLabel={activeLabel}
      articles={tab.articles}
      indexNumber={index}
      key={`popular-box__articles-list-${tab.tabName}`}
    />
  ));

  const popLabelComponent = tabs.map((tab, index) => {
    const isActiveLabel = activeLabel === index;

    return (
      <PopLabel
        isActiveLabel={isActiveLabel}
        setActiveLabel={!isActiveLabel ? () => setActiveLabel(index) : () => null}
        tabName={tab.tabName}
        isOneTab={tabs.length === 1}
        key={`popular-box__pop-label-${tab.tabName}`}
      />
    );
  });

  return (
    <section data-hydrate className="popular-box">
      <div
        className="popular-box__label"
        onKeyDown={(e) => {
          if (e.key === 'ArrowRight' && activeLabel < tabs.length - 1) {
            setActiveLabel(activeLabel + 1);
          } else if (e.key === 'ArrowLeft' && activeLabel > 0) {
            setActiveLabel(activeLabel - 1);
          }
        }}
      >
        {popLabelComponent}
      </div>
      {articlesComponent}
    </section>
  );
};

export default PopularBox;
