import React, { FC } from 'react';

interface IMetaData {
  src: string;
  width: number | undefined;
  height: number | undefined;
}

const ImageMetaData: FC<IMetaData> = ({ src, width, height }) => {
  return (
    <div itemScope itemType="https://schema.org/ImageObject">
      <meta itemProp="url" content={src} />
      {width && <meta itemProp="width" content={width.toString()} />}
      {height && <meta itemProp="height" content={height.toString()} />}
    </div>
  );
};

export default ImageMetaData;
