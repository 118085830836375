import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';

import { IImage } from '~/interfaces/components/Image';
import { SIZES, SRCSETSIZES } from '~/libs/mos-responsifier/constants';
import { setImagesSizesAttr } from '~/libs/mos-responsifier/imageSize';
import ResponsiveImage from '~/libs/mos-responsifier/ResponsiveImage';
import debounce from '~/utility/debounce';

import ImageMetaData from './inner-components/ImageMetaData/ImageMetaData';

/**
 * Use ImageWrapped 99% of the time
 * Should only need this, Image, if you're restricting height and need image to be
 * object-fit: contain, vertically
 */

export interface ImageProps extends IImage {
  onLoad?: () => void;
  useOriginalUrl?: boolean;
}

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  width,
  height,
  lazyLoading,
  missingImageSrc,
  className,
  addSEOMetaData,
  srcSetSizes = SRCSETSIZES,
  sizes = SIZES,
  onLoad,
  useOriginalUrl,
  dataHydrate,
}) => {
  const imageRef = useRef<HTMLDivElement>(null);
  const imageElementClass = clsx(className, 'image__image');

  useEffect(() => {
    const delayedEvent = debounce(() => setImagesSizesAttr(imageRef.current), 200);

    if (imageRef.current) {
      window.addEventListener('resize', delayedEvent);

      // Delay the first execution for CSS to kick in.  Only on first render we want to setTimeout
      setTimeout(delayedEvent, 200);
    }

    return () => {
      window.removeEventListener('resize', delayedEvent);
    };
  }, []); // Empty dependency array for one-time execution

  const ImageBits = () => {
    return (
      <div ref={imageRef} style={{ display: 'contents' }}>
        <ResponsiveImage
          key={src}
          src={src}
          alt={alt}
          className={imageElementClass}
          missingImageSrc={missingImageSrc}
          srcSetSizes={srcSetSizes}
          sizes={sizes}
          onLoad={onLoad}
          lazyLoading={lazyLoading}
          useOriginalUrl={useOriginalUrl}
          dataHydrate={dataHydrate}
        />
        {addSEOMetaData && (
          <ImageMetaData key={`meta-${src}`} src={src} width={width} height={height} />
        )}
        {/* once award is done <Image> & <ImageWrapped> can replace all twig images
  (award probably has to be in ImageWrapped like endorsement is) */}
      </div>
    );
  };

  return <ImageBits />;
};

export default React.memo(Image);
