import React from 'react';

interface IProps {
  isActiveLabel: boolean;
  isOneTab: boolean;
  setActiveLabel: (event: React.MouseEvent<HTMLElement>) => void;
  tabName: string;
}

const PopLabel: React.FC<IProps> = ({ isActiveLabel, setActiveLabel, tabName, isOneTab }) => {
  const ElementToRender: string | JSX.Element = isOneTab ? 'div' : 'button';
  const labelClassName = isActiveLabel ? 'popular-box__label__tab--active' : '';

  const attributes = !isOneTab
    ? {
        'data-hydrate': true,
        type: 'button',
        onClick: setActiveLabel,
        disabled: isActiveLabel,
      }
    : { role: 'heading' };
  return (
    // @ts-expect-error React element created with strings.
    <ElementToRender
      className={`popular-box__label__tab ${labelClassName}`}
      key={`pop-label__button-${tabName}`}
      {...attributes}
    >
      {tabName.toLocaleUpperCase()}
    </ElementToRender>
  ) as JSX.Element;
};

export default PopLabel;
